<template>
    <v-container fluid>
        CUSTOMER DIALOG
    </v-container>
</template>

<script>
export default {
    name: "CustomerDialog",
    prop:{}
}
</script>